/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        if($(window.location.hash).length > 0){
          $('html, body').animate({ scrollTop: $(window.location.hash).offset().top}, 1000);
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'find_a_park': {
      init: function() {


      },
      finalize: function() {
        //Load Parks Via Ajax
        $.ajax({
          type: "POST",
          url: pbAjax.ajaxurl,
          data: $('#ajax_pagination_form').serialize(), // serializes the form's elements.
          success: function(data)
          {
            var parks = jQuery.parseJSON( data );
            //console.log(parks)
            //Clear HTML and Markers
            jQuery('#parks_container .content').html('');
            deleteMarkers();

            if(parks['total_results'] != 0) {
              $.each( parks, function( key, value ) {
                var parkHasMicrosite = parks[key]['park_has_microsite'];
                var parkLatLng = {lat: parseFloat(parks[key]['_wp_geo_latitude']), lng: parseFloat(parks[key]['_wp_geo_longitude'])};;
                var parkTitle = parks[key]['park_title'];
                var parkDescription = parks[key]['park_description'];
                var parkAddress = parks[key]['park_address'];
                var parkImageURL = parks[key]['park_image_url'];

                var parkPermalink = parks[key]['park_permalink'];
                var parkTelephone = parks[key]['park_telephone'];
                var parkEmail = parks[key]['park_email'];
                var parkExcerpt= parks[key]['park_excerpt'];

                var parkCategories= parks[key]['park_categories'];


                //console.log(parkCategories);

                //Update Map Markers based on Search Results
                addMarker(parkLatLng,parkTitle,parkDescription,parkImageURL,parkPermalink,parkAddress,parkHasMicrosite);


                //Add Park Markup
                if(parkHasMicrosite == 'true') {
                  addParkWithMicrosite(parkTitle,parkImageURL,parkPermalink,parkAddress,parkTelephone,parkEmail,parkExcerpt,parkCategories);
                } else {
                  addParkWithNoMicrosite(parkTitle,parkAddress,parkTelephone,parkEmail,parkExcerpt,parkCategories);
                }

              });

              //var current_page = jQuery('#next_page').val(),
              //    next_page = eval(Number(current_page) + 1)
              //
              //jQuery('#next_page').val(next_page);

            }

            $('#loader_modal').modal('hide');
            $('#ajax_loader').remove();
            $('#parks_container').pajinate({
              items_per_page: 20,
              nav_label_prev: "Previous",
              show_first_last: false
            });
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
